<template>
  <div class="register-index-wrapper padding-top30">
    <div class="register-index-main border-top-radius30">
      <div class="register-index-header">
        <div class="register-index-header-title ub ub-btw padding30">
          <div v-if="headerLeftStatus === 'logo' " class="logo">Logo</div>
          <div v-if="headerLeftStatus === 'leftArrow' " class="close" @click="goback">
            <img
              src="@/assets/images/system/left-arrow.svg"
              alt="back"
              title="left-arrow"
            />
          </div>
          <div class="close" @click="goHome">
            <img
              src="@/assets/images/system/cross.svg"
              alt="close"
              title="close"
            />
          </div>
        </div>
        <div class="font24 font-weight900 padding-left30 padding-right30 margin-top20 padding-top20 margin-bottom20">{{ notice }}</div>
      </div>
      <div class="register-index-wrapper-slot padding-left30 padding-right30 padding-top20">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DialogWrapper',
  props: {
    notice: {
      type: String,
      default: 'Sign up'
    },
    headerLeftStatus: {
      type: String,
      // logo leftArrow
      default: 'logo'
    }
  },
  methods: {
    goback () {
      this.$router.back()
    },
    goHome () {
      this.$router.push('/')
    }
  }
}
</script>
<style lang="less">
.register-index-wrapper {
  background-color: #000;
  box-sizing: border-box;
  color: var(--main-text-1);
  height: 100vh;
  .register-index-main{
    height: 100%;
    background-color: var(--background-color-4);
  }
  .register-index-header {
    &-title {
      .logo {
        width: 160px;
        height: 64px;
        line-height: 64px;
        text-align: center;
        border-radius: 12px;
        background: rgba(91, 33, 203, 0.1);
        color: rgba(91, 33, 203, 0.3);
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
      }
      .close {
        line-height: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
