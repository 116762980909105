<template>
  <Wrapper :notice="$t('auth.verificationTitle')" :headerLeftStatus="'leftArrow'" class="df-styles">
    <span class="register-index-wrapper-message">
      {{ $t('auth.verificationLabel') }}
    </span>
    <van-field
      v-model="value"
      label=""
      readonly
      clickable
      @touchstart.native.stop="showKeyBoard = true"
    >
      <div slot="right-icon" @click="sendEmailSmsFn">
        <span v-show="countdown > 0">{{ countdown }}s</span>
        <span v-show="!(countdown > 0)" class="register-index-wrapper-recent">{{$t('auth.recent')}}</span>
      </div>
    </van-field>
    <van-button @click="onNext" :disabled="disabled" class="register-index-wrapper-submit">{{$t('next')}}</van-button>

    <van-number-keyboard
      v-model="value"
      :show="showKeyBoard"
      :maxlength="6"
      @blur="showKeyBoard = false"
    />
  </Wrapper>
</template>
<script>
import { sendEmailSms, verifyEmailSms, loginOtp } from '@/api/auth.js'
import Wrapper from '@/components/auth/wrapper/index.vue'
let FROM_ROUTER = {}

export default {
  components: { Wrapper },
  beforeRouteEnter (to, from, next) {
    console.log(to)
    console.log(from)
    FROM_ROUTER = from
    return next()
  },
  data () {
    return {
      value: '',
      showKeyBoard: false,
      // Login ResetPassWord SignUp
      verificationTarget: '',
      accountStatus: null,
      expiresTime: '2023-08-02 17:00:00',
      countdown: 60,
      countdownTime: null
    }
  },
  computed: {
    signType () {
      return this.$route.params.type
    },
    disabled () {
      return this.value.length !== 6
    }
  },
  onBeforeUnmount () {
    if (this.countdown > 0) {
      this.$store.dispatch('updateVerificationExpiresTime', new Date())
      this.$store.dispatch('updateVerificationCountdown', this.countdown)
    }
  },
  mounted () {
    this.verificationTarget = FROM_ROUTER.name
    this.checkCountdownFn()
  },

  beforeDestroy () {
    clearTimeout(this.countdownTime)
    this.countdownTime = null
  },
  methods: {
    getTimestamp (date = '') {
      const newData = date ? new Date(date) : new Date()
      return Date.parse(newData)
    },
    checkCountdownFn () {
      // this.$store.state.verify.expiresTime
      // 本来是存后段传来的过期时间
      // 现在用来存前端倒计时的开始时间/暂停时间
      // this.$store.state.verify.countdown  倒计时还剩多少秒
      this.expiresTime = this.$store.state.verify.expiresTime
      this.countdown = this.$store.state.verify.countdown
      const nowDate = this.getTimestamp()
      const expiresDate = this.getTimestamp(this.expiresTime)
      const lossTime = Math.floor((nowDate - expiresDate) / 1000)
      console.log(lossTime, 'lossTime', this.countdown - lossTime)
      if ((this.countdown - lossTime) > 0) {
        this.countdown = this.countdown - lossTime
        this.getCountdownFn()
      } else {
        this.countdown = 0
      }
    },
    getCountdownFn () {
      if (this.countdownTime) {
        this.countdownTime = clearTimeout(this.countdownTime)
        this.countdownTime = null
      }
      // const nowDate = this.getTimestamp()
      // const expiresDate = this.getTimestamp(this.expiresTime)
      // this.countdown = Math.floor((expiresDate - nowDate) / 1000)
      this.countdown = this.countdown - 1
      if (this.countdown > 0) {
        this.countdownTime = setTimeout(() => {
          this.getCountdownFn()
        }, 1000)
      }
    },
    sendEmailSmsFn () {
      if (this.countdown > 0) {
        return false
      }
      const email = this.$store.state.auth.email
      const mobile = this.$store.state.auth.phone
      const mobileCode = this.$store.state.auth.mobileCode
      sendEmailSms({
        email: email,
        mobile: mobile,
        mobile_code: mobileCode
      }).then((res) => {
        this.$toast.clear()
        if (res) {
          const resData = res.data
          this.accountStatus = resData.account_status
          this.$store.dispatch('updateVerificationCountdown', resData.expires_time)
          this.countdown = resData.expires_time
          this.getCountdownFn()
          // resData.expires_time
          this.$store.dispatch('updateVerificationExpiresTime', new Date())
          this.$store.dispatch('updateVerificationAccountStatus', resData.account_status)
        } else {

        }
        console.log(res, 'res')
      })
    },
    verifyEmailSmsFn () {
      const email = this.$store.state.auth.email
      const mobile = this.$store.state.auth.phone
      const mobileCode = this.$store.state.auth.mobileCode
      const verifyCode = this.value
      return verifyEmailSms({
        email: email,
        mobile: mobile,
        mobile_code: mobileCode,
        verify_code: verifyCode
      })
    },
    loginOtpFn () {
      const email = this.$store.state.auth.email
      const mobile = this.$store.state.auth.phone
      const mobileCode = this.$store.state.auth.mobileCode
      const sessionId = this.$store.state.verify.sessionId
      loginOtp({
        email: email,
        mobile: mobile,
        mobile_code: mobileCode,
        password: '',
        session_id: sessionId
      }).then(res => {
        this.$toast.clear()
        const resData = res.data
        this.$store.dispatch('updateToken', resData.token)
        this.$store.dispatch('updateVerificationTarget', 'Login')
        this.$router.push('/market')
        console.log(res, 'res ========= loginOtp')
      })
    },
    onNext () {
      const handlerObj = {
        // 主要逻辑 判断来源 来决定跳转
        // 只能从Login ResetPassWord SignUp
        // 跳转到此页面如果从其他页面到达此页面需要修改逻辑
        // 从返回上一页 返回到此页面已作处理
        Login: () => {
          this.loginOtpFn()
          // this.$store.dispatch('updateVerificationTarget', 'Login')
          // this.$router.push('/market')
        },
        ResetPassWord: () => {
          this.$store.dispatch('updateVerificationTarget', 'ResetPassWord')
          this.$router.push('/register/password')
        },
        SignUp: () => {
          this.$store.dispatch('updateVerificationTarget', 'SignUp')
          this.$router.push('/register/password')
        },
        ResetWithdraw: () => {
          this.$store.dispatch('updateVerificationTarget', 'ResetWithdraw')
          this.$router.push('/register/password')
        },
        ResetLoginPwd: () => {
          this.$store.dispatch('updateVerificationTarget', 'ResetLoginPwd')
          this.$router.push('/register/password')
        }
      }
      this.verifyEmailSmsFn().then((res) => {
        console.log(res)
        try {
          const resData = res.data
          this.$store.dispatch('updateVerificationSessionId', resData.session_id)
          if (resData.verify_status) {
            // 根据 signType 跳转对应的路径
            switch (this.signType) {
              case 'otp':
                this.verificationTarget = 'Login'
                break
              case 'signUp':
                this.verificationTarget = 'SignUp'
                break
              case 'resetPwd':
                this.verificationTarget = 'ResetPassWord'
                break
              case 'withdraw':
                this.verificationTarget = 'ResetWithdraw'
                break
              case 'loginPwd':
                this.verificationTarget = 'ResetLoginPwd'
                break
              default:
                this.verificationTarget = this.$store.state.verify.verificationTarget
            }
            console.log(this.verificationTarget, 'this.verificationTarget')
            handlerObj[this.verificationTarget]()
            if (this.verificationTarget !== 'Login') {
              this.$toast.clear()
            }
          }
        } catch (error) {
        }
      })
    }
  }
}
</script>
<style lang="less">
.register-index-wrapper {
  &-message {
    display: block;
    font-family: Roboto;
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
    color: var(--main-text-2);
  }
  &-recent {
    color: var(--primary-color);
    text-align: right;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
  }

  &-submit {
    margin-top: 48px;
    padding: 24px 0;
    width: 100%;
    height: 96px;
    border-radius: 16px;
    border: none;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 48px; /* 150% */
    background: linear-gradient(
      135deg,
      var(--line-gradient-start-color) 0%,
      var(--line-gradient-end-color) 100%
    );
  }
}
</style>
